import React from "react";

import Layout from "../../layout/Layout";
import SalonImage from "./assets/SalonImage";

import salonStyle from "./assets/Salon.module.scss";

const SalonPage = () => {
  return (
    <Layout title="Salon">
      <section name="salon" className={salonStyle["salon"]}>
        <div className={salonStyle["salon_container"]}>
          <div className={salonStyle["salon_title"]}>
            <h1>Svatební salon Delta</h1>
          </div>
          <div className={salonStyle["salon_content"]}>
            <p>
              Svatební salon Delta byl založen v lednu 1993 majitelkou Petrou
              Vyoralovou. Z malého salonu se během let rozrostl v jeden z
              největších a nejvýznamnějších salonů. V současné době je
              navštěvovaný zákaznicemi z celé České republiky, a to hlavně
              proto, že kolekce, které objednává, jsou exkluzivně ve Svatebním
              salonu Delta.
            </p>
            <p>
              Každý rok objednává nové kolekce na významných světových
              veletrzích v Londýně, Barceloně, Miláně nebo v New Yorku. Dává si
              záležet, aby každý z objednaných modelů byl originální. Vaše šaty
              pro vás na míru připraví a upraví tým zkušených švadlen, protože
              šaty se musí přizpůsobit vám a ne vy šatům. Vaše pohodlí a komfort
              je pro nás vším.
            </p>
            <p>
              Krédem našeho salonu je{" "}
              <strong>luxus, který si můžete dovolit</strong>, a to platí
              bezpodmínečně. Modely, které si u nás půjčíte patří mezi ty
              nejluxusnější, které nabízí světová svatební a společenská móda.
              Vy si je u nás můžete půjčit za zlomek pořizovací ceny,
              společenské šaty od 1900 do 3900 Kč a svatební od 4900 do 13900
              Kč. Své svatební i společenské šaty si u nás můžete samozřejmě i
              koupit.
            </p>
            <p>
              Svatební salon Delta vám usnadní i přípravy svatby, protože
              koordinaci svateb zajišťuje agentura{" "}
              <strong>DELTA WEDDINGS</strong>. Připravíme svatbu na klíč tak, že
              vy si pouze stanovíte základní požadavky a koordinátorka vám vše
              zajistí dle vašich požadavků.
            </p>
            <p>
              Na zkoušení šatů <strong>doporučujeme objednání</strong>, kdy pro
              vás bude nachystaná kabinka a asistentka, která se vám bude osobně
              věnovat.
            </p>
          </div>
          <div className={salonStyle["salon_image"]}>
            <SalonImage />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default SalonPage;
